import React, { useContext, useRef, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import clsx from "clsx";
import {
  B2BSpinner,
  ButtonWrap,
  CheckoutStepper,
  Icon,
  IconName,
  LoadingIndicator,
} from "halifax";
import {
  getParentState,
  ParentState,
  useCheckoutState,
  useCheckoutStateSelector as useSelector,
} from "@capone/checkout";

import "./styles.scss";
import { PATH_BOOK_CONFIRMATION, PATH_HOME } from "../../../../utils/paths";
import { ClientContext } from "../../../../App";
import {
  TRAVELERS_HEADING,
  HEADER_MAIN_TEXT,
  HEADER_SUB_TEXT,
  LOADING_TEXT,
  PAYMENT_HEADING,
  PAYMENT_SUBHEADING,
  PRIMARY_TRAVELER_HEADING,
  ADDITIONAL_DETAILS_HEADING,
} from "./textConstants";
import {
  CHANGE_CTA_TEXT,
  CONTACT_INFORMATION,
} from "../../../common/textConstants";
import {
  PriceBreakdown,
  TravelerSelection,
  TreesCard,
  ContactInfo,
  BookingInProgressModal,
  BookingErrorModal,
  ExperiencesRewardsAndPayment,
  PrimaryTravelerSelection,
  ExperienceSummaryPanel,
  AdditionalDetails,
} from "..";
import { Event, TEvent } from "../../state/events";
import { ExperiencesMachineContext } from "../../state/types";
import { getCheckoutSteps } from "./utils";
import {
  ExperiencesAdditionalDetailsSelectors,
  ExperiencesTravelerSelectors,
} from "../../../../checkout";
import { ShopContactInformation } from "../../../common";
import { ExperiencesSelectors } from "../../state/selectors";
import {
  AVAILABLE,
  getExperimentVariant,
  TREES_MODAL_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { PickupPointCompleted } from "../AdditionalDetails/components/PickupPointCompleted";

export type DesktopExperiencesBookValidationError =
  | "travelers"
  | "contact-info"
  | "primary-traveler"
  | "rewards"
  | "card";

export const DesktopExperiencesBookWorkflow = () => {
  const clientContext = useContext(ClientContext);
  const history = useHistory();
  const [validationErrorTypes, setValidationErrorTypes] =
    useState<DesktopExperiencesBookValidationError[]>();
  const travelerInfoSectionRef = useRef<HTMLDivElement>(null);
  const travelerPrimarySectionRef = useRef<HTMLDivElement>(null);
  const additionalDetailsSectionRef = useRef<HTMLDivElement>(null);
  const paymentSectionRef = useRef<HTMLDivElement>(null);

  const [state, send] = useCheckoutState<TEvent, ExperiencesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  const expShop = useSelector(ExperiencesSelectors.getExperienceShop);
  const selectedTravelers = useSelector(
    ExperiencesTravelerSelectors.getAllSelectedUserTravelers
  );
  const selectedPrimaryTraveler = useSelector(
    ExperiencesTravelerSelectors.getSelectedPrimaryTraveler
  );
  const hasAdditionalDetailsStep = useSelector(
    ExperiencesAdditionalDetailsSelectors.getHasAdditionalDetailsStep
  );

  const selectedPickupPoint = useSelector(
    ExperiencesAdditionalDetailsSelectors.getSelectedPickupPoint
  );
  const specialRequirement = useSelector(
    ExperiencesAdditionalDetailsSelectors.getSpecialRequirement
  );

  const expState = useExperiments();
  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesExperiment = treesModalExperiment === AVAILABLE;

  const goToTravelers = () => {
    send(Event.GO_TO_TRAVELER_SELECT);
  };

  const goToPrimaryTraveler = () => {
    send(Event.GO_TO_PRIMARY_TRAVELER_SELECT);
  };

  const goToAdditionalDetails = () => {
    send(Event.GO_TO_ADDITIONAL_DETAILS);
  };

  useEffect(() => {
    switch (parentState) {
      case ParentState.experiencesTravelerInformation:
        travelerInfoSectionRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case ParentState.experiencesPrimaryTraveler:
        travelerPrimarySectionRef.current?.scrollIntoView({
          behavior: "smooth",
        });
        break;
      case ParentState.experiencesAdditionalDetails:
        additionalDetailsSectionRef.current?.scrollIntoView({
          behavior: "smooth",
        });
        break;
      case ParentState.cardPayment:
        paymentSectionRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  }, [parentState]);

  useEffect(() => {
    // update path so header becomes visible for confirmation
    if (parentState === ParentState.bookingConfirmation) {
      history.replace(PATH_BOOK_CONFIRMATION);
    }
  }, [parentState]);

  useEffect(() => {
    send({ type: Event.SET_PLATFORM, platform: "desktop" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className="desktop-experiences-book-workflow-root">
      <CheckoutStepper
        steps={getCheckoutSteps(parentState)}
        headerCopy={HEADER_MAIN_TEXT}
        subHeaderCopy={HEADER_SUB_TEXT}
        logo={
          <ButtonWrap
            className="logo"
            onClick={() => {
              history.push(PATH_HOME);
            }}
          >
            {clientContext.logo}
          </ButtonWrap>
        }
        className="b2b combined-step"
      />

      <Box className="experiences-book-body-root">
        <Box className="experiences-book-body-wrapper">
          <Box className="experiences-book-body-left">
            <ExperienceSummaryPanel displayChangeBtn />

            <div
              className={clsx("experiences-book-section", {
                selected: [
                  ParentState.experiencesTravelerInformation,
                  ParentState.passport,
                  ParentState.contactInformation,
                ].includes(parentState),
              })}
              ref={travelerInfoSectionRef}
            >
              <Box className="experiences-book-section-main-content-wrapper">
                <Typography
                  variant="h3"
                  className="experiences-book-section-heading"
                >
                  {TRAVELERS_HEADING}
                </Typography>

                {parentState === ParentState.loading ? (
                  <LoadingIndicator
                    className="experiences-checkout-loading-indicator"
                    indicatorSize="small"
                    indicator={B2BSpinner}
                    message={LOADING_TEXT}
                  />
                ) : [
                    ParentState.experiencesTravelerInformation,
                    ParentState.passport,
                    ParentState.contactInformation,
                  ].includes(parentState) ? (
                  <>
                    <hr />

                    <TravelerSelection
                      validationErrorTypes={validationErrorTypes}
                    />

                    <hr />

                    <ContactInfo
                      validationErrorTypes={validationErrorTypes}
                      setValidationErrorTypes={setValidationErrorTypes}
                    />
                  </>
                ) : selectedTravelers.length ? (
                  <Box className="experiences-book-filled-section">
                    <Icon name={IconName.User} />
                    <Typography className="experiences-book-filled-section-content">
                      {selectedTravelers.reduce((nameString, person, i) => {
                        const firstLastName = `${person.givenName} ${person.surname}`;
                        if (i > 0) return `${nameString}, ${firstLastName}`;

                        return firstLastName;
                      }, "")}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              {![
                ParentState.experiencesTravelerInformation,
                ParentState.passport,
                ParentState.contactInformation,
              ].includes(parentState) &&
                !!selectedTravelers.length && (
                  <Box className="experiences-book-section-change-cta-wrapper">
                    <ButtonWrap onClick={goToTravelers}>
                      <Typography>{CHANGE_CTA_TEXT}</Typography>
                    </ButtonWrap>
                  </Box>
                )}
            </div>

            <div
              className={clsx("experiences-book-section", {
                selected: [ParentState.experiencesPrimaryTraveler].includes(
                  parentState
                ),
              })}
              ref={travelerPrimarySectionRef}
            >
              <Box className="experiences-book-section-main-content-wrapper">
                <Typography
                  variant="h3"
                  className="experiences-book-section-heading"
                >
                  {PRIMARY_TRAVELER_HEADING}
                </Typography>

                {[ParentState.experiencesPrimaryTraveler].includes(
                  parentState
                ) ? (
                  <>
                    <hr />
                    <PrimaryTravelerSelection
                      validationErrorTypes={validationErrorTypes}
                    />
                  </>
                ) : (
                  selectedPrimaryTraveler && (
                    <Box className="experiences-book-filled-section">
                      <Icon name={IconName.User} />
                      <Typography className="experiences-book-filled-section-content">
                        {selectedPrimaryTraveler.givenName}{" "}
                        {selectedPrimaryTraveler.surname}
                      </Typography>
                    </Box>
                  )
                )}
              </Box>
              {![ParentState.experiencesPrimaryTraveler].includes(
                parentState
              ) &&
                selectedPrimaryTraveler && (
                  <Box className="experiences-book-section-change-cta-wrapper">
                    <ButtonWrap onClick={goToPrimaryTraveler}>
                      <Typography>{CHANGE_CTA_TEXT}</Typography>
                    </ButtonWrap>
                  </Box>
                )}
            </div>

            {hasAdditionalDetailsStep && (
              <div
                className={clsx("experiences-book-section", {
                  selected: [ParentState.experiencesAdditionalDetails].includes(
                    parentState
                  ),
                })}
                ref={additionalDetailsSectionRef}
              >
                <Box className="experiences-book-section-main-content-wrapper">
                  <Typography
                    variant="h3"
                    className="experiences-book-section-heading"
                  >
                    {ADDITIONAL_DETAILS_HEADING}
                  </Typography>

                  {[ParentState.experiencesAdditionalDetails].includes(
                    parentState
                  ) ? (
                    <>
                      <hr />
                      <AdditionalDetails />
                    </>
                  ) : (
                    selectedPickupPoint && (
                      <Box className="experiences-book-filled-section">
                        <Icon name={IconName.B2BMapPin} />
                        <Typography className="experiences-book-filled-section-content">
                          <PickupPointCompleted />
                        </Typography>
                      </Box>
                    )
                  )}
                </Box>
                {![ParentState.experiencesAdditionalDetails].includes(
                  parentState
                ) &&
                  (selectedPickupPoint || specialRequirement) && (
                    <Box className="experiences-book-section-change-cta-wrapper">
                      <ButtonWrap onClick={goToAdditionalDetails}>
                        <Typography>{CHANGE_CTA_TEXT}</Typography>
                      </ButtonWrap>
                    </Box>
                  )}
              </div>
            )}

            <div
              className={clsx("experiences-book-section", {
                selected: [
                  ParentState.wallet,
                  ParentState.rewardsPayment,
                  ParentState.cardPayment,
                ].includes(parentState),
              })}
              ref={paymentSectionRef}
            >
              <Box className="experiences-book-section-main-content-wrapper">
                <Typography
                  variant="h3"
                  className="experiences-book-section-heading"
                >
                  {PAYMENT_HEADING(hasAdditionalDetailsStep)}
                </Typography>

                {parentState === ParentState.cartUpdate && (
                  <LoadingIndicator
                    className="experiences-checkout-loading-indicator"
                    indicatorSize="small"
                    indicator={B2BSpinner}
                    message={LOADING_TEXT}
                  />
                )}

                {[
                  ParentState.wallet,
                  ParentState.rewardsPayment,
                  ParentState.cardPayment,
                ].includes(parentState) ? (
                  <>
                    <Typography
                      variant="h4"
                      className="experiences-book-section-subheading"
                    >
                      {PAYMENT_SUBHEADING}
                    </Typography>
                    <hr />
                    <ExperiencesRewardsAndPayment
                      validationErrorTypes={validationErrorTypes}
                      setValidationErrorTypes={setValidationErrorTypes}
                    />
                  </>
                ) : null}
              </Box>
            </div>

            <div className="experiences-book-section contact-info-section">
              <Typography className="contact-info-title">
                {CONTACT_INFORMATION}
              </Typography>
              <ShopContactInformation
                supplierEmail={expShop?.supplierInfo.email}
                supplierPhone={expShop?.supplierInfo.phone}
              />
            </div>
            {isTreesExperiment && <TreesCard />}
          </Box>
          <Box className="experiences-book-body-right">
            <Box className="experiences-book-body-right-sticky-container">
              <PriceBreakdown
                setValidationErrorTypes={setValidationErrorTypes}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <BookingInProgressModal />
      <BookingErrorModal />
    </Box>
  );
};
