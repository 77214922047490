// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mOEdo51SsL9-l2v6yN9M9Q\\=\\={overflow:hidden}.mOEdo51SsL9-l2v6yN9M9Q\\=\\= .\\+s9\\+bzcoRNg7oire3uDnrw\\=\\={border-bottom:solid .5px var(--grey-11)}.mOEdo51SsL9-l2v6yN9M9Q\\=\\= .\\+s9\\+bzcoRNg7oire3uDnrw\\=\\= .UjtIkc1rvh2nptNyCwCTRA\\=\\={text-align:justify;margin-top:10px}.mOEdo51SsL9-l2v6yN9M9Q\\=\\= .\\+s9\\+bzcoRNg7oire3uDnrw\\=\\= .tKUWHFGhJbQUXrYCJxi4kQ\\=\\={margin:22px 0;font-weight:bold;color:var(--blue-9);font-size:14px;font-family:\"Optimist\"}.mOEdo51SsL9-l2v6yN9M9Q\\=\\= .\\+s9\\+bzcoRNg7oire3uDnrw\\=\\= .tKUWHFGhJbQUXrYCJxi4kQ\\=\\=:hover,.mOEdo51SsL9-l2v6yN9M9Q\\=\\= .\\+s9\\+bzcoRNg7oire3uDnrw\\=\\= .tKUWHFGhJbQUXrYCJxi4kQ\\=\\=:focus{text-decoration:underline}", "",{"version":3,"sources":["webpack://./../../cap1-modules/experiences-module/src/modules/shop/components/ShopWhatToExpect/styles.module.scss"],"names":[],"mappings":"AAAA,4BACE,eAAA,CACA,0DACE,uCAAA,CACA,sFACE,kBAAA,CACA,eAAA,CAGF,sFACE,aAAA,CACA,gBAAA,CACA,mBAAA,CACA,cAAA,CACA,sBAAA,CACA,wLAEE,yBAAA","sourcesContent":[".experiences-shop-what-to-expect-container {\n  overflow: hidden;\n  .experiences-shop-what-to-expect-description-container {\n    border-bottom: solid 0.5px var(--grey-11);\n    .experiences-shop-what-to-expect-description {\n      text-align: justify;\n      margin-top: 10px;\n    }\n\n    .experiences-shop-what-to-expect-description-button {\n      margin: 22px 0;\n      font-weight: bold;\n      color: var(--blue-9);\n      font-size: 14px;\n      font-family: \"Optimist\";\n      &:hover,\n      &:focus {\n        text-decoration: underline;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"experiences-shop-what-to-expect-container": "mOEdo51SsL9-l2v6yN9M9Q==",
	"experiences-shop-what-to-expect-description-container": "+s9+bzcoRNg7oire3uDnrw==",
	"experiences-shop-what-to-expect-description": "UjtIkc1rvh2nptNyCwCTRA==",
	"experiences-shop-what-to-expect-description-button": "tKUWHFGhJbQUXrYCJxi4kQ=="
};
export default ___CSS_LOADER_EXPORT___;
