import React from "react";
import {
  Box,
  Slider,
  ValueLabelProps,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { ExperiencesCancellationPolicy } from "redmond";
import { ShopComponentSkeleton } from "../../component";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import {
  CANCELLATION_POLICY_HEADER_TEXT,
  REFUND_DEADLINE,
  TODAY,
} from "../../textConstants";
import clsx from "clsx";

export interface IShopCancellation {
  isSkeleton?: boolean;
  cancellationPolicy?: ExperiencesCancellationPolicy;
}
export interface IShopCancellationPolicyProps extends IShopCancellation {
  selectedDate?: string | null;
  selectedTime?: string | null;
}

const Skeleton = () => (
  <Box className="experiences-shop-skeleton">
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
  </Box>
);

export const ShopCancellationPolicy = (props: IShopCancellationPolicyProps) => {
  const { isSkeleton, cancellationPolicy, selectedDate, selectedTime } = props;

  if (isSkeleton || !cancellationPolicy) {
    return <Skeleton />;
  }

  const today = dayjs();
  const experienceDay = dayjs(selectedDate);
  const policyEndsXDaysBefore = cancellationPolicy.policyEndsXDaysBefore;
  const lastDayToCancel = experienceDay.subtract(policyEndsXDaysBefore, "day");

  const percentageOfSlider = Math.trunc(
    (policyEndsXDaysBefore / (experienceDay.diff(today, "day") + 1)) * 100
  );

  const cancellationDeadline = dayjs(
    `${lastDayToCancel.format("YYYY/MM/DD")}T${selectedTime}`
  );

  const sliderMarks = [
    {
      value: 0,
      label: TODAY,
    },
    {
      value: 80,
      label: lastDayToCancel.format("MMMM D, YYYY"),
    },
    {
      value: 100,
      label: experienceDay.format("MMMM D, YYYY"),
    },
  ];

  const ValueLabelComponent = (props: ValueLabelProps) => {
    const { children } = props;

    return (
      <Tooltip
        classes={{
          popper: styles["experiences-shop-cancellation-popper"],
          tooltip: styles["experiences-shop-cancellation-tooltip"],
        }}
        enterTouchDelay={0}
        placement="top"
        title={
          <Box>
            <Typography
              className={styles["experiences-shop-cancellation-tooltip-title"]}
            >
              {cancellationDeadline.format("h:mm a")}
            </Typography>
            <Typography
              className={
                styles["experiences-shop-cancellation-tooltip-subtitle"]
              }
            >
              {REFUND_DEADLINE}
            </Typography>
          </Box>
        }
        open
      >
        {children}
      </Tooltip>
    );
  };

  return (
    <Box className={styles["experiences-shop-cancellation-container"]}>
      {percentageOfSlider >= 0 &&
        cancellationPolicy.percentageRefundable > 0 && (
          <Box className={styles["experiences-shop-refundable-content"]}>
            <Typography
              variant="body1"
              className={styles["experiences-shop-cancellation-header"]}
              dangerouslySetInnerHTML={{
                __html: CANCELLATION_POLICY_HEADER_TEXT(
                  cancellationPolicy.percentageRefundable,
                  cancellationDeadline.format("h:mm a on MMMM D, YYYY")
                ),
              }}
            />
            <Box
              className={clsx(
                styles["experiences-shop-cancellation-slider-container"]
              )}
            >
              <Slider
                ValueLabelComponent={ValueLabelComponent}
                disabled
                onChange={() => {}}
                value={80}
                marks={sliderMarks}
                valueLabelDisplay={"on"}
              />
            </Box>
          </Box>
        )}
      <Typography
        variant="body2"
        className={styles["experiences-shop-cancellation-details"]}
      >
        {cancellationPolicy.cancellationPolicy}
      </Typography>
    </Box>
  );
};
