import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Box, Divider, Typography } from "@material-ui/core";
import { ShopComponentSkeleton } from "../../component";
import {
  ExperienceBadge,
  ExperiencesAgeBandEnum,
  ExperiencesPriceBreakdown,
  RewardsAccount,
  SpecificAgeBand,
} from "redmond";
import {
  AvailabilityReviewRating,
  AvailabilityDuration,
  AvailabilityLanguage,
  ExperienceTag,
  roundToNearestHalf,
  Icon,
  IconName,
  ActionLink,
  getPriceString,
  getRewardText,
} from "halifax";
import * as textConstants from "../../textConstants";
import * as commonConstants from "../../../common/textConstants";
import { IShopOverview } from "../ShopOverview/component";
import {
  IShopCancellationPolicyProps,
  ShopCancellationPolicy,
} from "../ShopCancellationPolicy/component";
import { ExperiencesMobilePopoverCard } from "../../../common/ExperiencesMobilePopoverCard";

export interface IShopMobileHeaderCardProps extends IShopOverview {
  isFreeCancellation?: boolean;
  pricingBreakdown?: ExperiencesPriceBreakdown;
  largestValueAccount?: RewardsAccount;
  ageBands?: {
    [key in ExperiencesAgeBandEnum]?: SpecificAgeBand;
  };
  cancellationPolicyProps: IShopCancellationPolicyProps | null;
}

const Skeleton = () => (
  <Box className={styles["experiences-shop-skeleton"]}>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="medium" />
    </Box>
  </Box>
);

export const ShopMobileHeaderCard = (props: IShopMobileHeaderCardProps) => {
  const {
    isSkeleton = false,
    title,
    rating,
    duration,
    languageGuides,
    badges,
    experienceMaxTravelers,
    isFreeCancellation,
    pricingBreakdown,
    largestValueAccount,
    ageBands,
    cancellationPolicyProps,
  } = props;

  const [isBadgeExcellenceModalOpen, setBadgeExcellenceModalOpen] =
    useState(false);

  const [openCancellationPolicyModal, setOpenCancellationPolicyModal] =
    useState(false);

  if (!title || !rating || duration === undefined || !languageGuides) {
    return null;
  }

  let priceTitle = "";
  let rewardsTitle = "";

  if (pricingBreakdown) {
    let chosenLineItem = pricingBreakdown.lineItems.find((item) => {
      return item.specificAgeBand.ageBand === ExperiencesAgeBandEnum.ADULT;
    });
    if (!chosenLineItem) {
      chosenLineItem = pricingBreakdown.lineItems[0];
    }
    priceTitle = textConstants.PRICE_TITLE_TEXT(
      getPriceString({
        price: chosenLineItem.specificAgeBand.pricePerTraveler.fiat.value,
        currencySymbol:
          chosenLineItem.specificAgeBand.pricePerTraveler.fiat.currencySymbol,
      }),
      chosenLineItem.specificAgeBand.ageBand.toLowerCase()
    );
    rewardsTitle = largestValueAccount
      ? `${getRewardText({
          reward:
            chosenLineItem.specificAgeBand.pricePerTraveler.rewards[
              largestValueAccount.accountReferenceId
            ],
        })}`
      : "";
  } else if (ageBands && ageBands.Adult) {
    priceTitle = textConstants.PRICE_TITLE_TEXT(
      getPriceString({
        price: ageBands.Adult.pricePerTraveler.fiat.value,
        currencySymbol: ageBands.Adult.pricePerTraveler.fiat.currencySymbol,
      }),
      "adult"
    );
    rewardsTitle = largestValueAccount
      ? `${getRewardText({
          reward:
            ageBands.Adult.pricePerTraveler.rewards[
              largestValueAccount.accountReferenceId
            ],
        })}`
      : "";
  }

  const subtitle = experienceMaxTravelers
    ? textConstants.MAX_TRAVELER_TEXT(experienceMaxTravelers)
    : undefined;

  return (
    <Box className={styles["experiences-shop-mobile-header-card"]}>
      <Typography
        className="experiences-shop-mobile-header-card-title"
        variant="h6"
      >
        {title}
      </Typography>
      <Box className={styles["experiences-shop-mobile-header-card-subtext"]}>
        <AvailabilityReviewRating
          reviewCount={rating.numberOfReviews}
          scaledScore={roundToNearestHalf(rating.reviewAverage)}
          shortReviews
        />
        <Divider
          className={
            styles["experiences-shop-mobile-header-card-subtext-dividers"]
          }
          orientation="vertical"
        />
        <AvailabilityDuration duration={duration} isSkeleton={isSkeleton} />
        <Divider
          className={
            styles["experiences-shop-mobile-header-card-subtext-dividers"]
          }
          orientation="vertical"
        />
        <AvailabilityLanguage
          languages={languageGuides.map(
            (language) => language.languageDisplayName
          )}
          isSkeleton={isSkeleton}
        />
      </Box>
      <Typography
        className={styles["experiences-shop-mobile-header-card-price-title"]}
        dangerouslySetInnerHTML={{
          __html: textConstants.getPriceAndRewardsCopy({
            price: priceTitle,
            rewards: rewardsTitle,
          }),
        }}
      />
      {subtitle && (
        <Typography
          className={
            styles["experiences-shop-mobile-header-card-price-subtitle"]
          }
          variant={"body2"}
        >
          {subtitle}
        </Typography>
      )}
      {isFreeCancellation && (
        <Box
          className={
            styles["experiences-shop-mobile-header-card-cancellation-policy"]
          }
        >
          <Typography
            className={
              styles[
                "experiences-shop-mobile-header-card-cancellation-policy-text"
              ]
            }
          >
            <Icon name={IconName.CheckCircleFilledGreen} />
            {commonConstants.FREE_CANCELLATION}
          </Typography>
          <ActionLink
            onClick={() => {
              setOpenCancellationPolicyModal(true);
            }}
            className={
              styles[
                "experiences-shop-mobile-header-card-cancellation-policy-button"
              ]
            }
            content={
              <Typography
                className={
                  styles[
                    "experiences-shop-mobile-header-card-cancellation-policy-text"
                  ]
                }
              >
                {commonConstants.VIEW_POLICY_DETAILS}
                <Icon name={IconName.InfoCircle} />
              </Typography>
            }
          ></ActionLink>
        </Box>
      )}
      {badges && badges.length > 0 && (
        <Box
          className={
            styles["experience-shop-mobile-header-card-tags-container"]
          }
        >
          {badges.map((badge) => {
            // Shop cancellation policy banner instead of cancellation policy tag on mobile
            if (badge === ExperienceBadge.FreeCancellation) {
              return null;
            }
            return (
              <ExperienceTag
                key={badge}
                badge={badge}
                onBadgeClick={
                  badge === ExperienceBadge.BadgeOfExcellence
                    ? () => {
                        if (!isBadgeExcellenceModalOpen)
                          setBadgeExcellenceModalOpen(true);
                      }
                    : undefined
                }
              />
            );
          })}
        </Box>
      )}
      {cancellationPolicyProps && (
        <ExperiencesMobilePopoverCard
          headerText={commonConstants.CANCELLATION_POLICY}
          content={<ShopCancellationPolicy {...cancellationPolicyProps} />}
          isOpen={openCancellationPolicyModal}
          setOpen={setOpenCancellationPolicyModal}
        />
      )}
      {isSkeleton ? <Skeleton /> : null}
    </Box>
  );
};
