import { ChildMachineConfig, ParentState } from "@capone/checkout";

import { actions } from "./actions";
import { Event } from "../../../modules/book/state/events";
import { AdditionalDetailsState } from "./types";
import { services } from "./services";

export const getMachineState = (config: ChildMachineConfig) => ({
  id: ParentState.experiencesAdditionalDetails,
  initial: AdditionalDetailsState.idle,
  states: {
    [AdditionalDetailsState.idle]: {},
    [AdditionalDetailsState.error]: {},
    [AdditionalDetailsState.validate]: {
      invoke: {
        src: services.validateAdditionalDetailsService,
        onDone: [
          {
            target: config.nextStateFromReview || ParentState.cartQuote,
          },
        ],
        onError: {
          target: `#${config.parentMachineId}.${ParentState.experiencesAdditionalDetails}.${AdditionalDetailsState.error}`,
          actions: actions.setAdditionalDetailsError,
        },
      },
    },
  },
  on: {
    [Event.NEXT]: `#${config.parentMachineId}.${ParentState.experiencesAdditionalDetails}.${AdditionalDetailsState.validate}`,
    [Event.PREVIOUS]: `#${config.parentMachineId}.${ParentState.experiencesPrimaryTraveler}`,
    [Event.SET_SELECTED_PICKUP_POINT]: {
      actions: actions.setSelectedPickupPoint,
    },
    [Event.SET_HOTEL_SELECTION]: {
      actions: actions.setHotelSelection,
    },
    [Event.SET_HOTEL_NAME]: {
      actions: actions.setHotelName,
    },
    [Event.SET_ADDRESS]: {
      actions: actions.setAddress,
    },
    [Event.SET_CITY]: {
      actions: actions.setCity,
    },
    [Event.SET_ZIP_CODE]: {
      actions: actions.setZipCode,
    },
    [Event.SET_SPECIAL_REQUIREMENT]: {
      actions: actions.setSpecialRequirement,
    },
    [Event.CLEAR_ADDITIONAL_DETAILS_ERROR]: {
      actions: actions.clearErrors,
    },
  },
});
