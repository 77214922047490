import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import {
  ExperienceHopOnHopOff,
  ExperienceItinerary,
  ExperienceItineraryEnum,
  ExperienceItineraryLineItem,
  ExperienceMultiDayTour,
  ExperienceStandard,
} from "redmond";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import { Icon, IconName } from "halifax";
import clsx from "clsx";

import styles from "./styles.module.scss";
import * as textConstants from "../../../../textConstants";

export interface ItineraryDetailsProps {
  itinerary?: ExperienceItinerary;
  mobile?: boolean;
}

export const ItineraryDetails = (props: ItineraryDetailsProps) => {
  const { itinerary, mobile = false } = props;

  if (!itinerary) {
    return null;
  }

  const renderLineItemsItinerary = (
    lineItems: ExperienceItineraryLineItem[]
  ) => {
    let indexToShow = 0;
    const lineItemsItinerary: React.JSX.Element[] = [];
    lineItems.forEach((lineItem, index) => {
      const [showDetails, setShowDetails] = React.useState(false);
      const showIcon = lineItem.durationMinutes && lineItem.durationMinutes > 0;
      if (showIcon) {
        indexToShow = indexToShow + 1;
      }
      lineItemsItinerary.push(
        <TimelineItem>
          <TimelineSeparator>
            {showIcon ? (
              <TimelineDot className={styles["timeline-dot-with-content"]}>
                <Typography
                  className={styles["time-dot-content"]}
                  variant="body1"
                >
                  {indexToShow}
                </Typography>
              </TimelineDot>
            ) : (
              <TimelineDot className={styles["timeline-dot"]} />
            )}
            {index < lineItems.length - 1 && (
              <TimelineConnector className={styles["timeline-connector"]} />
            )}
          </TimelineSeparator>
          <TimelineContent className={styles["timeline-content-wrapper"]}>
            <Box className={styles["timeline-content-container"]}>
              <Box className={styles["timeline-content-title-container"]}>
                <Typography
                  className={styles["timeline-content-title"]}
                  variant="h6"
                  component="span"
                >
                  {lineItem.location}
                </Typography>
                {lineItem.passByWithoutStopping && (
                  <Typography
                    variant="h6"
                    component="span"
                    className={styles["timeline-content-title-subtext"]}
                  >
                    {textConstants.PASS_BY}
                  </Typography>
                )}
              </Box>
              {lineItem.durationMinutes && (
                <Typography
                  variant="body2"
                  component="span"
                  className={styles["timeline-content-duration"]}
                >
                  {textConstants.PARSE_TO_MINUTES(lineItem.durationMinutes)}
                </Typography>
              )}
              {lineItem.description && (
                <Box className={styles["timeline-content-description"]}>
                  {showDetails && (
                    <Typography
                      variant="body1"
                      className={
                        styles["timeline-content-description-expanded"]
                      }
                    >
                      {lineItem.description}
                    </Typography>
                  )}
                  <Link
                    component="button"
                    onClick={() => setShowDetails(!showDetails)}
                    className={styles["timeline-view-details-link"]}
                  >
                    {showDetails
                      ? textConstants.HIDE_DETAILS
                      : textConstants.VIEW_DETAILS}
                  </Link>
                </Box>
              )}
            </Box>
          </TimelineContent>
        </TimelineItem>
      );
    });

    return lineItemsItinerary;
  };

  const renderStandard = (itinerary: ExperienceStandard) => {
    const lineItems = itinerary.itineraryItems;

    if (lineItems.length <= 0) {
      return null;
    }

    return (
      <Box className={styles["itinerary-details-timeline-container"]}>
        <Timeline align="left">
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot className={styles["timeline-dot-with-icon"]}>
                <Icon name={IconName.B2BMapPin} />
              </TimelineDot>
              <TimelineConnector className={styles["timeline-connector"]} />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="h6" component="span">
                {textConstants.STARTING_POINT}
              </Typography>
            </TimelineContent>
          </TimelineItem>
          {renderLineItemsItinerary(lineItems)}
        </Timeline>
      </Box>
    );
  };

  const renderMultiDayTour = (itinerary: ExperienceMultiDayTour) => {
    return itinerary.days.map((day) => {
      return (
        <Box className={styles["multiday-day-container"]}>
          <Box className={styles["multiday-day-number-container"]}>
            <Typography
              className={styles["multiday-day-number"]}
            >{`Day ${day.dayNumber}`}</Typography>
          </Box>
          <Typography variant="h4" className={styles["multiday-day-title"]}>
            {day.title}
          </Typography>
          <Typography
            variant="body1"
            className={styles["multiday-day-stops-number"]}
          >{`${day.items.length} stops`}</Typography>
          {renderLineItemsItinerary(day.items)}
        </Box>
      );
    });
  };

  const renderHopOnHopOffTour = (itinerary: ExperienceHopOnHopOff) => {
    return itinerary.routes.map((route) => {
      return (
        <Box className={styles["hoponhopoff-route-container"]}>
          <Box className={styles["hoponhopoff-title-container"]}>
            <Typography
              className={styles["hoponhopoff-title"]}
            >{`Route ${route.name}`}</Typography>
          </Box>

          <Typography
            variant="h4"
            className={styles["hoponhopoff-operating-schedule"]}
          >
            {route.operatingSchedule}
          </Typography>
          <Typography>{`${route.stops.length} stops`}</Typography>
          {renderLineItemsItinerary(
            route.stops.map((stop) => {
              return {
                location: stop.stopLocation,
                description: stop.description,
              } as ExperienceItineraryLineItem;
            })
          )}
        </Box>
      );
    });
  };

  return (
    <Box
      className={clsx(styles["itinerary-details-container"], {
        [styles["mobile"]]: mobile,
      })}
    >
      {itinerary?.ExperienceItinerary ===
        ExperienceItineraryEnum.ExperienceItineraryStandard &&
        renderStandard(itinerary as ExperienceStandard)}
      {itinerary?.ExperienceItinerary ===
        ExperienceItineraryEnum.ExperienceItineraryMultiDayTour &&
        renderMultiDayTour(itinerary as ExperienceMultiDayTour)}
      {itinerary?.ExperienceItinerary ===
        ExperienceItineraryEnum.ExperienceItineraryHopOnHopOff &&
        renderHopOnHopOffTour(itinerary as ExperienceHopOnHopOff)}
    </Box>
  );
};
