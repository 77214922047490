import { PersonId, Person, Payment } from "redmond";
import {
  ContactEvents,
  CartQuoteEvents,
  CardPaymentEvents,
  CartFulfillEvents,
  RewardsPaymentEvents,
  WalletEvents,
} from "@capone/checkout";
import { IContactInfo } from "halifax";
import { PickupPointOption } from "../../../checkout";

export enum Event {
  // CommonEvents
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
  CHANGE = "CHANGE",
  GO_IDLE = "GO_IDLE",
  RESET_CONTEXT = "RESET_CONTEXT",

  //  TravelerInformationEvents
  SELECT_TRAVELER = "SELECT_TRAVELER",
  ADD_TRAVELER = "ADD_TRAVELER",
  UPDATE_TRAVELER = "UPDATE_TRAVELER",
  DELETE_TRAVELER = "DELETE_TRAVELER",
  OPEN_TRAVELER_FORM = "OPEN_TRAVELER_FORM",
  OPEN_TRAVELER_PICKER = "OPEN_TRAVELER_PICKER",
  OPEN_PASSPORT_FORM = "OPEN_PASSPORT_FORM",
  SET_CURRENT_TRAVELER = "SET_CURRENT_TRAVELER",
  CLEAR_TRAVELER_INFORMATION_ERROR = "CLEAR_TRAVELER_INFORMATION_ERROR",
  ANSWER_BOOKING_QUESTIONS = "ANSWER_BOOKING_QUESTIONS",

  // ContactInformationEvents
  SET_CONTACT_INFO = "SET_CONTACT_INFO",
  CLEAR_CONTACT_ERROR = "CLEAR_CONTACT_ERROR",

  // PassportEvents
  UPDATE_PASSPORT_AND_CONTINUE = "UPDATE_PASSPORT_AND_CONTINUE",
  VALIDATE_TRAVELERS = "VALIDATE_TRAVELERS",
  CLEAR_PASSPORT_ERROR = "CLEAR_PASSPORT_ERROR",

  // AdditionalDetailsEvents
  SET_SELECTED_PICKUP_POINT = "SET_SELECTED_PICKUP_POINT",
  SET_HOTEL_SELECTION = "SET_HOTEL_SELECTION",
  SET_HOTEL_NAME = "SET_HOTEL_NAME",
  SET_ADDRESS = "SET_ADDRESS",
  SET_CITY = "SET_CITY",
  SET_ZIP_CODE = "SET_ZIP_CODE",
  SET_SPECIAL_REQUIREMENT = "SET_SPECIAL_REQUIREMENT",
  CLEAR_ADDITIONAL_DETAILS_ERROR = "CLEAR_ADDITIONAL_DETAILS_ERROR",

  // ScheduleQuote
  CLEAR_SCHEDULE_QUOTE_ERROR = "CLEAR_SCHEDULE_QUOTE_ERROR",

  // PaymentInformation
  OPEN_PAYMENT_FORM = "OPEN_PAYMENT_FORM",
  CLOSE_PAYMENT_FORM = "CLOSE_PAYMENT_FORM",
  SET_APPLIED_BALANCE = "SET_APPLIED_BALANCE",
  VERIFY_PAYMENT_METHOD = "VERIFY_PAYMENT_METHOD",
  DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD",
  SET_PAYMENT_METHOD_ID = "SET_PAYMENT_METHOD_ID",
  SET_SELECTED_PAYMENT_METHOD_ID = "SET_SELECTED_PAYMENT_METHOD_ID",
  CLEAR_PAYMENT_ERROR = "CLEAR_PAYMENT_ERROR",

  // Review
  GO_TO_TRAVELER_SELECT = "GO_TO_TRAVELER_SELECT",
  GO_TO_CONTACT_INFORMATION = "GO_TO_CONTACT_INFORMATION",
  GO_TO_PRIMARY_TRAVELER_SELECT = "GO_TO_PRIMARY_TRAVELER_SELECT",
  GO_TO_ADDITIONAL_DETAILS = "GO_TO_ADDITIONAL_DETAILS",

  // ScheduleFulfill
  CLEAR_SCHEDULE_FULFILL_ERROR = "CLEAR_SCHEDULE_FULFILL_ERROR",

  // Confirmation
  GO_HOME = "GO_HOME",

  // Transition to state from query params
  GO_TO_CART_QUOTE = "GO_TO_CART_QUOTE",
  GO_TO_CARD_PAYMENT = "GO_TO_CARD_PAYMENT",
  GO_TO_REVIEW = "GO_TO_REVIEW",
  GO_TO_BOOKING_CONFIRMATION = "GO_TO_BOOKING_CONFIRMATION",

  SET_PLATFORM = "SET_PLATFORM",
}

export interface NextEvent {
  type: Event.NEXT;
}
export interface PreviousEvent {
  type: Event.PREVIOUS;
}
export interface SelectTravelerEvent {
  type: Event.SELECT_TRAVELER;
  singleTravelerWorkflow?: boolean;
  travelerId?: PersonId;
  data?: { id: string; value: Array<Person>; onUpdate?: boolean };
}
export interface AddTravelerEvent {
  type: Event.ADD_TRAVELER;
}
export interface UpdateTravelerEvent {
  type: Event.UPDATE_TRAVELER;
  person: Person;
  onUpdate?: boolean;
}
export interface DeleteTravelerEvent {
  type: Event.DELETE_TRAVELER;
  personId: PersonId;
}

export interface AnswerBookingQuestionsEvent {
  type: Event.ANSWER_BOOKING_QUESTIONS;
  travelerId: PersonId;
  data: { heightFt: string; heightIn: string; weight: string };
}

export interface GoIdleEvent {
  type: Event.GO_IDLE;
}

export interface setCurrentTravelerEvent {
  type: Event.SET_CURRENT_TRAVELER;
  traveler?: Person;
}

export interface OpenTravelerFormEvent {
  type: Event.OPEN_TRAVELER_FORM;
}

export interface OpenTravelerPickerEvent {
  type: Event.OPEN_TRAVELER_PICKER;
}

export interface OpenPassportFormEvent {
  type: Event.OPEN_PASSPORT_FORM;
}

export interface ClearTravelerInformationError {
  type: Event.CLEAR_TRAVELER_INFORMATION_ERROR;
}

export interface OpenPaymentFormEvent {
  type: Event.OPEN_PAYMENT_FORM;
}

export interface ClosePaymentFormEvent {
  type: Event.CLOSE_PAYMENT_FORM;
}

export interface SetAppliedBalanceEvent {
  type: Event.SET_APPLIED_BALANCE;
  appliedBalance: number;
}

export interface VerifyPaymentMethodEvent {
  type: Event.VERIFY_PAYMENT_METHOD;
  token: string;
}

export interface DeletePaymentMethodEvent {
  type: Event.DELETE_PAYMENT_METHOD;
  paymentMethod: {
    paymentId: string;
  };
}

export interface SetPaymentMethodIdEvent {
  type: Event.SET_PAYMENT_METHOD_ID;
  data: {
    selectedPaymentMethodId: number | string;
    paymentMethods: Payment[];
  };
}

export interface SetSelectedPaymentMethodIdEvent {
  type: Event.SET_SELECTED_PAYMENT_METHOD_ID;
  paymentMethod: {
    paymentId: string | undefined;
    rewardsAccountId: string | undefined;
  };
}

export interface ClearScheduleQuoteError {
  type: Event.CLEAR_SCHEDULE_QUOTE_ERROR;
}

export interface ClearScheduleFulfillError {
  type: Event.CLEAR_SCHEDULE_FULFILL_ERROR;
}

export interface GoToTravelerSelect {
  type: Event.GO_TO_TRAVELER_SELECT;
}

export interface GoToContactInformation {
  type: Event.GO_TO_CONTACT_INFORMATION;
}

export interface GoToPrimaryTravelerSelect {
  type: Event.GO_TO_PRIMARY_TRAVELER_SELECT;
}

export interface GoToAdditionalDetails {
  type: Event.GO_TO_ADDITIONAL_DETAILS;
}

export interface GoHomeEvent {
  type: Event.GO_HOME;
}

export interface UpdatePassportAndContinue {
  type: Event.UPDATE_PASSPORT_AND_CONTINUE;
  person: Person;
}

export interface ValidateTravelers {
  type: Event.VALIDATE_TRAVELERS;
}

export interface ClearPassportError {
  type: Event.CLEAR_PASSPORT_ERROR;
}

export interface ResetContextEvent {
  type: Event.RESET_CONTEXT;
}

export interface GoToCardPayment {
  type: Event.GO_TO_CARD_PAYMENT;
}
export interface GoToReview {
  type: Event.GO_TO_REVIEW;
}

export interface GoToBookingConfirmation {
  type: Event.GO_TO_BOOKING_CONFIRMATION;
}

export interface GoToCartQuote {
  type: Event.GO_TO_CART_QUOTE;
}

export interface ClearContactError {
  type: Event.CLEAR_CONTACT_ERROR;
}

export interface SetContactInfoEvent {
  type: Event.CHANGE;
  contactInfo: IContactInfo;
}

export interface ClearPaymentError {
  type: Event.CLEAR_PAYMENT_ERROR;
}

export interface SetPlatformEvent {
  type: Event.SET_PLATFORM;
  platform: "desktop" | "mobile";
}

export interface SetSelectedPickupPointEvent {
  type: Event.SET_SELECTED_PICKUP_POINT;
  data: PickupPointOption;
}

export interface SetHotelSelectionEvent {
  type: Event.SET_HOTEL_SELECTION;
  data: string;
}

export interface SetHotelNameEvent {
  type: Event.SET_HOTEL_NAME;
  data: string;
}

export interface SetAddressEvent {
  type: Event.SET_ADDRESS;
  data: string;
}

export interface SetCityEvent {
  type: Event.SET_CITY;
  data: string;
}

export interface SetZipCodeEvent {
  type: Event.SET_ZIP_CODE;
  data: string;
}

export interface SetSpecialRequirementEvent {
  type: Event.SET_SPECIAL_REQUIREMENT;
  data: string;
}

export interface clearAdditionalDetailsError {
  type: Event.CLEAR_ADDITIONAL_DETAILS_ERROR;
}

export type TEvent =
  | AddTravelerEvent
  | ClearTravelerInformationError
  | ClearScheduleFulfillError
  | ClearScheduleQuoteError
  | DeleteTravelerEvent
  | GoHomeEvent
  | GoIdleEvent
  | GoToContactInformation
  | GoToTravelerSelect
  | GoToPrimaryTravelerSelect
  | GoToAdditionalDetails
  | NextEvent
  | OpenTravelerFormEvent
  | OpenTravelerPickerEvent
  | OpenPassportFormEvent
  | PreviousEvent
  | SelectTravelerEvent
  | setCurrentTravelerEvent
  | UpdateTravelerEvent
  | AnswerBookingQuestionsEvent
  | OpenPaymentFormEvent
  | ClosePaymentFormEvent
  | SetAppliedBalanceEvent
  | VerifyPaymentMethodEvent
  | DeletePaymentMethodEvent
  | SetPaymentMethodIdEvent
  | SetSelectedPaymentMethodIdEvent
  | UpdatePassportAndContinue
  | ValidateTravelers
  | ClearPassportError
  | ResetContextEvent
  | ClearContactError
  | GoToCardPayment
  | GoToReview
  | GoToBookingConfirmation
  | GoToCartQuote
  | ClearPaymentError
  // Contact Information Events
  | ContactEvents
  | SetContactInfoEvent
  // Cart Quote Events
  | CartQuoteEvents
  // Cart Fulfill Events
  | CartFulfillEvents
  // Card Payment Events
  | CardPaymentEvents
  // Rewards Payment Events
  | RewardsPaymentEvents
  | WalletEvents
  | SetPlatformEvent
  // Additional Details Events
  | SetSpecialRequirementEvent
  | SetSelectedPickupPointEvent
  | SetHotelSelectionEvent
  | SetHotelNameEvent
  | SetAddressEvent
  | SetCityEvent
  | SetZipCodeEvent
  | clearAdditionalDetailsError;
