import React from "react";
import { useHistory } from "react-router";
import clsx from "clsx";
import {
  GenericInfoPopup,
  Icon,
  IconName,
  useDeviceTypes,
  usePrevious,
} from "halifax";
import {
  MODAL_ALERT,
  ModalAlertProperties,
  ModalCategoryType,
  ModalScreens,
} from "redmond";
import { PurchaseError } from "@b2bportal/purchase-api";
import {
  CardPaymentEventTypes,
  cartFulfillSelectors,
  CartQuoteEventType,
  cartQuoteSelectors,
  getParentState,
  ParentState,
  useCheckoutState,
  useCheckoutStateSelector,
} from "@capone/checkout";

import "./styles.scss";
import { TEvent } from "../../state/events";
import { ExperiencesMachineContext } from "../../state/types";
import { getErrorModalProps } from "./utils";
import { PATH_HOME } from "../../../../utils/paths";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export const BookingErrorModal = () => {
  const history = useHistory();
  const { matchesMobile } = useDeviceTypes();

  const quoteErrorOpen = useCheckoutStateSelector(
    cartQuoteSelectors.getOpenCartQuoteErrorModal
  );
  const quoteError = useCheckoutStateSelector(
    cartQuoteSelectors.getCartQuoteError
  );
  const fulfillErrorOpen = useCheckoutStateSelector(
    cartFulfillSelectors.getOpenCartFulfillErrorModal
  );
  const fulfillError = useCheckoutStateSelector(
    cartFulfillSelectors.getCartFulfillError
  );

  const priceChange = useCheckoutStateSelector(
    cartQuoteSelectors.getCartQuotePriceChange
  );

  const open =
    fulfillErrorOpen ||
    quoteErrorOpen ||
    !!(priceChange?.hasDifference && !priceChange.acknowledged);

  const error = fulfillError || quoteError;

  const previousError = usePrevious(error);
  const previousQuoteError = usePrevious(quoteError);
  const previousFulfillError = usePrevious(fulfillError);

  const [state, send] = useCheckoutState<TEvent, ExperiencesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  // const clearError = () => {
  //   send(CartQuoteEventType.CLEAR_CART_QUOTE_ERROR);
  //   send(CartFulfillEventType.CLEAR_CART_FULFILL_ERROR);
  // };

  const handleAcknowledgePriceChange = () => {
    send(CardPaymentEventTypes.ACKNOWLEDGE_PRICE_CHANGE);
  };

  const handleRetryQuote = () => {
    send(CartQuoteEventType.RETRY_QUOTE);
  };

  const handleGoToAvailability = () => {
    // TODO just go home for now
    history.push(PATH_HOME);
  };

  const errorModalProps = getErrorModalProps(
    error?.type || previousError?.type, // fallback to previous error state to prevent flashing of default error on modal close
    (error?.data || previousError?.data) as PurchaseError[],
    priceChange,
    !!(quoteError || previousQuoteError),
    !!(fulfillError || previousFulfillError),
    handleAcknowledgePriceChange,
    handleRetryQuote,
    handleGoToAvailability
  );

  const modalEventProperties: ModalAlertProperties = {
    type: error?.type || "",
    primary_button:
      errorModalProps.buttons?.[0]?.buttonText?.replaceAll(" ", "_") || "",
    secondary_button:
      errorModalProps.buttons?.[1]?.buttonText.replaceAll(" ", "_") || "",
    screen: ModalScreens.EXPERIENCES_CHECKOUT,
    category: ModalCategoryType.TROUBLE,
    modal_subtitle: errorModalProps.subtitle as string,
    modal_title: errorModalProps.title as string,
    agent_subtitle: errorModalProps.subtitle as string,
    agent_title: errorModalProps.title as string,
    funnel: "experiences",
    step: parentState,
  };

  React.useEffect(() => {
    if (open)
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          ...modalEventProperties,
        },
      });
  }, [open]);

  return (
    <GenericInfoPopup
      open={false}
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      className={clsx("experiences-book-error-modal", {
        mobile: matchesMobile,
      })}
      zIndex={1301}
      {...errorModalProps}
    />
  );
};
