import {
  BookingQuestion,
  BookingQuestionId,
  ExperiencesShopResponse,
} from "redmond";
import { AdditionalDetailsValidation } from "./types";

export enum PickupPointOption {
  SelectPickup = "select-pickup",
  PickupLater = "pickup-later",
  NoPickup = "no-pickup-needed",
}

export interface ExperiencesAdditionalDetailsContext {
  hasAdditionalDetailsStep: boolean;
  requiredBookingQuestions: BookingQuestion[];

  // pick up fields
  selectedPickupPoint?: PickupPointOption;
  hotelSelection?: any | null; // TBD on option passed to auto complete
  hotelName?: string;
  address?: string;
  city?: string;
  zipCode?: string;

  // special requirement field
  specialRequirement?: string;

  errors: AdditionalDetailsValidation[];
}

// we only handle these per booking questions in additional steps
const HANDLED_BOOKING_QUESTIONS: BookingQuestionId[] = [
  BookingQuestionId.SpecialRequirements,
  BookingQuestionId.PickupPoint,
];

export const getInitialAdditionalDetailsContext = (
  experienceShop?: ExperiencesShopResponse
): ExperiencesAdditionalDetailsContext => {
  const bookingQuestions = experienceShop?.bookingQuestions?.perBooking || [];

  const requiredBookingQuestions = bookingQuestions.filter((question) =>
    HANDLED_BOOKING_QUESTIONS.includes(question.id)
  );

  return {
    hasAdditionalDetailsStep: !!requiredBookingQuestions?.length,
    requiredBookingQuestions,
    errors: [],
  };
};
