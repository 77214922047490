import { assign, DoneInvokeEvent } from "xstate";
import {
  ContactSelectors,
  ParentContextWithWallet,
  ParentState,
} from "@capone/checkout";
import { TravelWalletCredit, Transaction } from "redmond";
import { Product } from "@b2bportal/purchase-api";

import { ExperiencesMachineContext } from "../types";
import { SetPlatformEvent } from "../events";
import { getOpaquePayments } from "../selectors/common";
import {
  getBookingQuestionAnswers,
  getExperiencePriceBreakdown,
  getExperienceShop,
} from "../selectors/experiences";
import { ExperiencesTravelerSelectors } from "../../../../checkout";

export const setPaymentFulfillParams = assign(
  (ctx: ExperiencesMachineContext) => {
    const opaquePayments = getOpaquePayments({ context: ctx });

    ctx[ParentState.cartFulfill].fulfillRequestPayments?.push(
      ...opaquePayments
    );

    return ctx;
  }
);

export const setPrepareDetailsFulfillParams = assign(
  (context: ExperiencesMachineContext) => {
    const shopDetails = getExperienceShop({ context });
    const priceBreakdown = getExperiencePriceBreakdown({ context });
    const contactInformation = ContactSelectors.getContactInformation({
      context,
    });
    const primaryTraveler =
      ExperiencesTravelerSelectors.getSelectedPrimaryTraveler({ context });
    const travelers = ExperiencesTravelerSelectors.getAllSelectedUserTravelers({
      context,
    });
    const bookingQuestionAnswers = getBookingQuestionAnswers({ context });

    if (!shopDetails || !priceBreakdown || !primaryTraveler) {
      throw new Error("Missing shop response or price breakdown");
    }

    const { id, name, languageGuides, duration } = shopDetails;
    const { startTime, date, optionId, totalPriceWithFees } = priceBreakdown;

    context[ParentState.cartFulfill].fulfillPrepareDetails?.push({
      type: "Experiences" as Product,
      value: {
        // TODO shopOptions need to supply lang guides so that user can choose one
        languageGuide: languageGuides[0],
        bookerInfo: {
          firstName: primaryTraveler.surname,
          lastName: primaryTraveler.givenName,
        },
        bookingQuestionAnswers,
        communication: {
          phone: contactInformation.phoneNumber,
          email: contactInformation.email,
        },
        experienceDetails: {
          experienceId: id,
          experienceOptionId: optionId,
          productTitle: name,
          totalPurchasePrice: totalPriceWithFees.fiat,
          category: "tour",
          // startLocation: logistics.startLocations[0],
          startLocation: {
            name: "José Julián Martí Statue",
            address: "W 59th St &, Center Drive, New York, NY 10019, USA",
            coordinates: {
              lat: 40.76616717275202,
              lon: -73.97555915820764,
            },
          },
          startDate: date,
          startTime,
          durationMinutes: duration,
          travelers: travelers.map((traveler) => ({
            firstName: traveler.surname,
            lastName: traveler.givenName,
          })),
          numberOfTravelers: travelers.length,
        },
      },
    });

    return context;
  }
);

export const setPlatform = assign(
  (ctx: ExperiencesMachineContext, event: SetPlatformEvent) => {
    ctx.platform = event.platform;

    return ctx;
  }
);

export const setWalletCredits = assign(
  (context: ParentContextWithWallet, e: unknown) => {
    const event = e as DoneInvokeEvent<[TravelWalletCredit, Transaction[]]>;
    const [credits, transactions] = event.data;

    if (credits) {
      context[ParentState.wallet].credits = credits;
    }

    if (transactions) {
      context[ParentState.wallet].creditTransactionHistory = transactions;
    }

    return context;
  }
);
