import {
  ExperiencesPriceBreakdown,
  ExperiencesShopResponse,
  IPassengerCounts,
  IPerson,
  PersonId,
  SessionInfo,
} from "redmond";
import {
  IContactInfo,
  ParentState,
  getCartInitialContext,
  getIntialWalletContext,
  getInitialRewardsPaymentContext,
} from "@capone/checkout";
import { ExperiencesMachineContext } from "./types";

import { getInitialAdditionalDetailsContext } from "../../../checkout";

export const getInitialExperiencesMachineContext = function (
  experienceShop?: ExperiencesShopResponse,
  priceBreakdown?: ExperiencesPriceBreakdown,
  travelerCounts?: IPassengerCounts
): ExperiencesMachineContext {
  return {
    ...getCartInitialContext(),
    platform: "desktop",
    sessionInfo: {} as SessionInfo,
    experienceShop,
    priceBreakdown,
    travelerCounts,
    [ParentState.experiencesTravelerInformation]: {
      selectedTravelerIds: [] as Array<PersonId>,
      userTravelers: [] as Array<IPerson>,
      visited: false,
      isValidated: false,
      numTravelerAlertDismissed: true,
    },
    [ParentState.contactInformation]: {
      contactInfo: {} as IContactInfo,
      visited: false,
    },
    [ParentState.experiencesPrimaryTraveler]: {
      selectedPrimaryTravelerId: undefined,
    },
    [ParentState.experiencesAdditionalDetails]: {
      ...getInitialAdditionalDetailsContext(experienceShop),
    },
    [ParentState.review]: {
      visited: false,
    },
    [ParentState.rewardsPayment]: { ...getInitialRewardsPaymentContext() },
    [ParentState.wallet]: { ...getIntialWalletContext() },
    [ParentState.cardPayment]: {
      paymentMethods: [],
      selectedPaymentMethodId: undefined,
      spreedlyToken: undefined,
      error: undefined,
      visited: false,
    },
    [ParentState.bookingConfirmation]: {},
  };
};
